// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD2XoI2piQGcC6O1JJl8DP2mqw3Wn0yGws",
  authDomain: "wigboardadmin.firebaseapp.com",
  databaseURL: "https://wigboardadmin-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "wigboardadmin",
  storageBucket: "wigboardadmin.appspot.com",
  messagingSenderId: "971932047732",
  appId: "1:971932047732:web:97792892ad347dfe5eb421",
  measurementId: "G-QNHDBVRRDK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;
