import { useState, useEffect } from 'react';
import { auth } from './firebase';
import axios from 'axios';
import baseURL from './Global';

const projectURL = baseURL + "cupdate.php?key=iiiAs33fnnjasDcc&allProjects=1";

function AllProjects() {
  
  const [data, setData] = useState(null);
// Commenting out as the current hosting doesn't allow connections through different domains
  /*
  let config = {
    method : 'GET',
    mode : 'cors',
    headers: {
      'Content-Type' : 'application/json',
    }
  }
  useEffect(() => {
    axios.get(projectURL,config).then((response) => {
       setData(response.data);
     });
   }, []);
*/


  if (!data) return (
<div>

      <iframe src={projectURL} />
      </div>
);

    return (
      <>
      {data.map((record, i) => <p>{record.name} => id:{record.project_id} status:{record.status}</p>)}
      </>
    );
}

export default AllProjects;
