import { setUserProperties } from 'firebase/analytics';
import AllQuestions from './AllQuestions';
import './App.css';
import AllProjects from './AllProjects';
import QuestionCountUpdateForm from './QuestionCountUpdateForm';
import QuestionStatusUpdateForm from './QuestionStatusUpdateForm';

function Admin() {
  return (
    <><div className="intro">
      <h1>Wigboard Admin</h1>
     </div>
      
      <div className="">
<QuestionCountUpdateForm />
      </div>
      <div className="">
<QuestionStatusUpdateForm />
      </div>
      <hr/>
    <div className="all-questions">
      <h3>All Questions</h3>
      <AllQuestions />
  </div>
  <div className="all-projects">
      <h3>All Projects</h3>
      <AllProjects />
  </div>

</>
  );
}

export default Admin;
