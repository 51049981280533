import { useState, useEffect, useRef } from 'react';
import { auth } from './firebase';
import axios from 'axios';
import baseURL from './Global';

const updateURL = baseURL + "cupdate.php?key=iiiAs33fnnjasDcc&question=";

function QuestionStatusUpdateForm() {
  const questionIdRef = useRef();
  const statusRef = useRef();

  function submitHandler(event) {
      event.preventDefault();
      let myWindow;

      const enteredQuestion = questionIdRef.current.value;
      const enteredStatus = statusRef.current.value;

      let submitURL = updateURL+enteredQuestion+"&status="+enteredStatus;

      // bypassing hosting restrictions by updating backend from a browser
      myWindow = window.open(submitURL, "", "width=200,height=100");
//      axios.get(submitURL).then((response) => { });
}

  return (
      <form className="questionStatus-form" onSubmit={submitHandler}>
          <fieldset>
          <legend>Status update</legend>
          <div className="data-input">
              <label htmlFor='question'>Question ID:</label><br/>
              <input type='text' required id='question' ref={questionIdRef}/>
          </div>
          <div className="data-input">
              <label htmlFor='status'>Status:</label><br/>
              <input type='text' required id='status' ref={statusRef}/>
          </div>
          <div>
              <button>Submit</button>
          </div>
          </fieldset>
      </form>
  );
}

export default QuestionStatusUpdateForm;
