import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from './firebase';
import {  signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import Admin from './Admin';

const Home = () => {
  const navigate = useNavigate();
  const [isLogged, setLogged] = useState(false);
 
  const handleLogout = () => {               
      signOut(auth).then(() => {
      // Sign-out successful.
          //navigate("/");
          console.log("Signed out successfully")
      }).catch((error) => {
      // An error happened.
      });
  }
    useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/firebase.User
              const uid = user.uid;
              
              // ...
              setLogged(true);
//              console.log("uid", uid);
 
            } else {
              // User is signed out
              // ...
              setLogged(false);
              console.log("user is logged out");
              navigate("/login");
            }
          });
         
    }, [])

    return (

      <>
      {isLogged?
      <>
      <Admin />
      <div>
      <button onClick={handleLogout}>
                Logout
            </button>
    </div></>
    :
    <></>
    }
    </>
    );
}
 
export default Home;