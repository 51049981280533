import { useState, useEffect } from 'react';
import { auth } from './firebase';
import axios from 'axios';
import baseURL from './Global';

const projectURL = baseURL + "cupdate.php?key=iiiAs33fnnjasDcc&allQuestions=1";

function AllQuestions() {
  const [data, setData] = useState(null);

/*  
  useEffect(() => {
    axios.get(projectURL).then((response) => {
      setData(response.data);
    });
  }, []);
*/
  if (!data) return (
<div>

<iframe src={projectURL} />
</div>
      );
    
      return (
        <>
        {data.map((record, i) => <p>{record.question} => id:{record.question_id} status:{record.badge} {record.option1}:{record.count1} {record.option2}:{record.count2}</p>)}
        </>
      );
}

export default AllQuestions;
