import { useState, useEffect, useRef } from 'react';
import { auth } from './firebase';
import axios from 'axios';
import baseURL from './Global';

const updateURL = baseURL + "cupdate.php?key=iiiAs33fnnjasDcc&count=";

function QuestionUpdateForm() {
  const questionIdRef = useRef();
  const count1Ref = useRef();
  const count2Ref = useRef();

  function submitHandler(event) {
      event.preventDefault();
      let myWindow;

      const enteredQuestion = questionIdRef.current.value;
      const enteredCount1 = count1Ref.current.value;
      const enteredCount2  = count2Ref.current.value;

      let submitURL = updateURL+enteredQuestion+","+enteredCount1+","+enteredCount2;

      // bypassing hosting restrictions by updating backend from a browser
      myWindow = window.open(submitURL, "", "width=200,height=100");
      //myWindow.close();
//      axios.get(submitURL).then((response) => { });
  }

  return (
      <form className="questionCount-form" onSubmit={submitHandler}>
          <fieldset>
          <legend>Count update</legend>
          <div className="data-input">
              <label htmlFor='question'>Question ID:</label><br/>
              <input type='text' required id='question' ref={questionIdRef}/>
          </div>
          <div className="data-input">
              <label htmlFor='option1'>Option 1 count:</label><br/>
              <input type='text' required id='count1' ref={count1Ref}/>
          </div>
          <div className="data-input">
              <label htmlFor='option2'>Option 2 count:</label><br/>
              <input type='text' required id='count2' ref={count2Ref}/>
          </div>
          <div>
              <button>Submit</button>
          </div>
          </fieldset>
      </form>
  );
}

export default QuestionUpdateForm;
